/**
 * Navigation
 *
 * Gestion du comportement responsive de la navigation.
 */

$(document).ready(function () {

    let windowWidth = $(window).width(), // Largeur d'écran
        isMobile; // Flag indiquant si l'on est en mobile ou non

    /**
     * Prépare la navigation pour son comportement MOBILE
     *
     * Déclenche différents resets sur les éléments du menu
     * afin d'éviter les conflits (notamment sur les écouteurs).
     */
    function navResetForMobile() {
        isMobile = true; // Flag

        $('.nav-primary__item').off('mouseenter');
        $('.nav-primary__item').off('mouseleave');
        $('.js-nav-primary__link').off('click');
        $('.nav-primary__lvl-1 > .nav-primary__item').removeClass('is-show');
    }

    /**
     * Prépare la navigation pour son comportement DESKTOP
     *
     * Déclenche différents resets sur les éléments du menu
     * afin d'éviter les conflits (notamment sur les écouteurs).
     */
    function navResetForDesktop() {
        isMobile = false; // Flag

        $('.js-nav-primary__link').off('click');
        $('.nav-primary__lvl-1 > .nav-primary__item').removeClass('is-show');
    }


    /**
     * Affiche / masque la navigation MOBILE
     *
     * Il s'agit du comportement déclenché sur l'icône "burger"
     * ou "croix" suivant l'état d'affichage du menu.
     */
    function displayNavForMobile() {
        let $trigger = $('[data-toggle="display-nav"]'),
            $target = $('.header'),
            $headerMobile = $('.header-mobile'),
            $navPrimary = $('.nav-primary'),
            $icoBurger = $('.burger'),
            $icoCross = $('.cross');

        $trigger.on('click', function(){
            $target.toggleClass('is-show');
            $navPrimary.toggleClass('is-show');
            $headerMobile.toggleClass('is-active');
            $icoBurger.toggleClass('d-none');
            $icoCross.toggleClass('d-none d-block');
        });
    }

    /**
     * Affiche / masque le sous-menu DESKTOP
     *
     * Différentes actions sont déclenchées sur ces écouteurs :
     *
     *    - click : désactive le lien au clic
     *    - mouseenter :
     *      - ajoute la classe permettant l'affichage du sous-menu
     *      - déclenche la fonction de calcul de positionnement
     *    - mouseleave
     *      - retire la classe permettant l'affichage du sous-menu
     *      - retire les styles ajoutés par la fonction de calcul de positionnement
     */
    function navForDesktop() {
        navResetForDesktop();

        $('.js-item-menu').each(function(){
            let $item = $(this),
                $link = $item.children('.js-nav-primary__link'),
                $sublevel = $item.find('.nav-primary__lvl-2');

            $link.on('click', function(e) {
                e.preventDefault();
            });
        });
    }

    /**
     * Affiche / masque le sous-menu MOBILE
     *
     * Différentes actions sont déclenchées sur ces écouteurs :
     *
     *    - click :
     *      - désactive le lien au clic
     *      - ajoute / retire la classe permettant l'affichage du sous-menu
     *      - affiche / masque les éléments 1er niv. non cliqués & affiche / masque éléments 2e niv. correspondants
     */
    function navForMobile() {
        navResetForMobile();

        $('.js-item-menu').each(function(){
            let $item = $(this),
                $itemSiblings = $item.siblings();
                $link = $item.children('.js-nav-primary__link');

            $link.on('click', function(e){
                e.preventDefault();

                $item.parent().children().not($item).not('.nav-primary__aside').toggle();
                $itemSiblings.removeClass('is-show');
                $item.toggleClass('is-show');
            });
        });
    }

    /**
     * Initialise la navigation suivant la taille d'écran
     */
    function navInit() {
        if (breakpoints.isDesktop()) {
            if (isMobile !== false) {
                navForDesktop();
            }
        } else {
            if (isMobile !== true) {
                navForMobile();
            }
        }
    }

    displayNavForMobile(); // L'affichage des déclencheurs est géré en CSS
    navInit();
    $(window).resize(function(){
        navInit();
    });

    /**
     * Au hover des liens du menu, l'image d'illustration est changée.
     */
    $(document).on('mouseover', '.nav-primary [data-picture]', function(){
        if($(this).data('picture')) {
            $(this).closest('.nav-primary__list').find('figure img').attr('src', $(this).data('picture'));
        }
    });

});
